<template>
    <div>
      <v-overlay :value="loading" :opacity="0.35" color="#d3d3d3">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    <v-form @submit.prevent="sendCreateFixture">
      <v-select v-model="fixture.competition" :items="getCompetitions"  item-text="name" return-object label="Competition" @change="updateTeams()"></v-select>
      <v-select v-model="fixture.homeTeam" :items="getTeams" item-text="name" return-object label="Home Team"></v-select>
      <v-select v-model="fixture.awayTeam" :items="getTeams" item-text="name" return-object label="Away Team"></v-select>
      <v-text-field v-model="fixture.venue" label="Venue"></v-text-field>
      <v-text-field v-model="fixture.date" label="Date" type="date"></v-text-field>
      <v-menu
        ref="menu"
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="fixture.time"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="fixture.time"
            label="Kick off time"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="menu2"
          v-model="fixture.time"
          full-width
          @click:minute="$refs.menu.save(fixture.time)"
        ></v-time-picker>
      </v-menu>
      <v-select v-model="fixture.inPlay" :items="['True', 'False']" label="In Play"></v-select>
      <v-text-field v-model="fixture.matchday" label="Matchday" type="number"></v-text-field>
      <v-text-field v-model="fixture.stage" label="Stage"></v-text-field>
      <v-btn type="submit" color="primary">Create Fixture</v-btn>
    </v-form>
  </div>
</template>
  
  <script>
  import { mapGetters, mapActions } from 'vuex';
  export default {
    data() {
      return {
        fixture: {
          competition: {},
          homeTeam: '',
          awayTeam: '',
          venue: '',
          date: '',
          matchday: '',
          stage: '',
          time: '',
          inPlay: true
        },
        menu2: false,
        loading: false
      };
    },
    mounted(){
        this.fetchCompetitions();
    },
    methods: {
      ...mapActions(["fetchCompetitions", "fetchTeams", "createFixture"]),
      async sendCreateFixture() {
        this.loading = true;
        let resp = await this.createFixture(this.fixture);
        if(resp){
          this.loading = false;
          this.fixture = {
            competition: this.fixture.competition,
            homeTeam: '',
            awayTeam: '',
            venue: '',
            date: this.fixture.date,
            matchday: '',
            stage: '',
            time: this.fixture.time,
            inPlay: true
          }
        }
      },
      updateTeams(){
       this.fetchTeams(this.fixture.competition.sport);
      }
    },
    computed: {
        ...mapGetters(["getCompetitions", "getTeams"])
    }
  };
  </script>
  